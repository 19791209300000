const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    LOAD_ORGS_USERS_BEGIN: true,
    LOAD_ORGS_USERS_SUCCESS: true,
    LOAD_ORGS_USERS_ERROR: true,
    DELETE_ORG_BEGIN: true,
    DELETE_ORG_SUCCESS: true,
    DELETE_ORG_ERROR: true,
    CREATE_ORG_SUCCESS: true,
    CREATE_ORG_ERROR: true,
    MANAGE_ORG_BEGIN: true,
    UPDATE_ORG_SUCCESS: true,
    UPDATE_ORG_ERROR: true,
    DELETE_USER_BEGIN: true,
    DELETE_USER_SUCCESS: true,
    DELETE_USER_ERROR: true,
    MANAGE_USER_BEGIN: true,
    CREATE_USER_SUCCESS: true,
    CREATE_USER_ERROR: true,
    UPDATE_USER_SUCCESS: true,
    UPDATE_USER_ERROR: true,
    RESEND_INVITE_SUCCESS: true,
    RESEND_INVITE_BEGIN: true,
    RESEND_INVITE_ERROR: true,
});
