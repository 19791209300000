const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    UPDATE_SELECTED_JURISDICTION: true,
    LOAD_MAP_BEGIN: true,
    LOAD_MAP_ERROR: true,
    LOAD_MAP_SUCCESS: true,
    SAVE_MAP_BEGIN: true,
    SAVE_MAP_ERROR: true,
    SAVE_MAP_SUCCESS: true,
    DELETE_MAP_BEGIN: true,
    DELETE_MAP_ERROR: true,
    DELETE_MAP_SUCCESS: true,
    UPDATE_MAP_BEGIN: true,
    UPDATE_MAP_ERROR: true,
    UPDATE_MAP_SUCCESS: true,
    LOAD_MAP_LIST_BEGIN: true,
    LOAD_MAP_LIST_ERROR: true,
    LOAD_MAP_LIST_SUCCESS: true,
    LOAD_ORG_MAP_LIST_BEGIN: true,
    LOAD_ORG_MAP_LIST_ERROR: true,
    LOAD_ORG_MAP_LIST_SUCCESS: true,
    RESET_ACTIVE_MAP: true,
});
