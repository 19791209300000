import Colors from '../../styles/colors';

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        textAlign: 'center',
        fontSize: '5rem',
        fontWeight: 'bold',
        lineHeight: '5.8rem',
        letterSpacing: '0.00304rem',
        color: Colors.grey1,
        paddingTop: 54,
        paddingBottom: 22,
    },
    description: {
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.9rem',
        color: Colors.grey4,
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        height: 190,
        width: 313,
    },
});

export default styles;
