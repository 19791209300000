import AppTypes from '../action-types/app';

const internals = {
    initial: () => ({
        apiStatus: {
            error: false,
            errorMsg: null,
            completed: false,
            successMsg: null,
        },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case AppTypes.API_RESET:
        return {
            ...state,
            apiStatus: {
                error: false,
                errorMsg: null,
                completed: false,
                successMsg: null,
            },
        };
    case AppTypes.API_ERROR:
        return {
            ...state,
            apiStatus: {
                error: true,
                errorMsg: payload,
                completed: true,
                successMsg: null,
            },
        };
    case AppTypes.API_SUCCESS:
        return {
            ...state,
            apiStatus: {
                error: false,
                errorMsg: null,
                completed: true,
                successMsg: payload,
            },
        };
    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
