import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import colors from '../../../styles/colors';

const ErrorTitle = styled(Typography)(() => ({
    fontSize: '5rem',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '5.8rem',
    paddingTop: '54px',
    paddingBottom: '22px',
    color: colors.grey1,
}));

export default ErrorTitle;
