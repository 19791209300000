import AdminTypes from '../action-types/admin';

const internals = {
    initial: () => ({
        orgsAndUsers: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        manageUser: {
            complete: false,
            error: false,
            errorMsg: '',
        },
        deleteUser: {
            complete: false,
            error: false,
            errorMsg: '',
        },
        manageOrg: {
            complete: false,
            error: false,
            errorMsg: '',
        },
    }),
};

const AdminReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case AdminTypes.LOAD_ORGS_USERS_BEGIN:
        return {
            ...state,
            orgsAndUsers: {
                ...state.orgsAndUsers,
                complete: false,
                error: false,
                errorMsg: null,
            },
        };
    case AdminTypes.LOAD_ORGS_USERS_ERROR:
        return {
            ...state,
            orgsAndUsers: {
                ...state.orgsAndUsers,
                complete: true,
                error: true,
                errorMsg: payload.errorMsg,
                data: null,
            },
        };
    case AdminTypes.LOAD_ORGS_USERS_SUCCESS:
        return {
            ...state,
            orgsAndUsers: {
                ...state.orgsAndUsers,
                complete: true,
                error: false,
                errorMsg: null,
                data: payload.data,
            },
        };
    case AdminTypes.MANAGE_ORG_BEGIN:
    case AdminTypes.UPDATE_ORG_SUCCESS:
    case AdminTypes.UPDATE_ORG_ERROR:
    case AdminTypes.CREATE_ORG_SUCCESS:
    case AdminTypes.CREATE_ORG_ERROR:
        return {
            ...state,
            manageOrg: payload,
        };
    case AdminTypes.MANAGE_USER_BEGIN:
    case AdminTypes.CREATE_USER_SUCCESS:
    case AdminTypes.CREATE_USER_ERROR:
    case AdminTypes.UPDATE_USER_SUCCESS:
    case AdminTypes.UPDATE_USER_ERROR:
        return {
            ...state,
            manageUser: payload,
        };
    case AdminTypes.DELETE_USER_BEGIN:
    case AdminTypes.DELETE_USER_ERROR:
        return {
            ...state,
            deleteUser: payload,
        };
    case AdminTypes.DELETE_USER_SUCCESS: {
        const { id } = payload;
        const updatedOrgs = state.orgsAndUsers.data?.map((org) => {
            const updatedOrg = {
                ...org,
                users: org.users.filter((user) => user.id !== id),
            };
            return updatedOrg;
        });
        return {
            ...state,
            deleteUser: payload,
            orgsAndUsers: {
                ...state.orgsAndUsers,
                data: updatedOrgs,
            },
        };
    }

    default:
        // do nothing
    }
    return state;
};

export default AdminReducer;
