import { combineReducers } from 'redux';
import Auth from './auth';
import User from './user';
import Map from './map';
import MapSets from './map-sets';
import Admin from './admin';
import App from './app';
import Organization from './organizations';

const Reducers = () => combineReducers({
    auth: Auth,
    user: User,
    map: Map,
    mapsets: MapSets,
    admin: Admin,
    app: App,
    organization: Organization,
});

export default Reducers;
