import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';

import themer from '../../styles/material-theme';
import Styles from './styles';

import ErrorImg from './images/500-error@2x.png';
import ErrorTitle from '../../routes/error/components/error-title';
import ErrorDescription from '../../routes/error/components/error-description';

const ErrorBoundary = ({ classes }) => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themer}>
            <main>
                <Container className={classes.root}>
                    <ErrorTitle>
                        Oops!
                    </ErrorTitle>

                    <ErrorDescription>
                        Something went wrong on our end.
                    </ErrorDescription>

                    <ErrorDescription>
                        Please try again.
                    </ErrorDescription>

                    <Button
                        variant="contained"
                        component={Link}
                        sx={{
                            marginTop: 3,
                            marginBottom: 2,
                        }}
                        to="/"
                        fullWidth
                        disableElevation
                    >
                        Back to Home
                    </Button>

                    <div className={classes.iconContainer}>
                        <img src={ErrorImg} alt="" className={classes.icon} />
                    </div>
                </Container>
            </main>
        </ThemeProvider>
    </StyledEngineProvider>
);

ErrorBoundary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ErrorBoundary);
