export default {
    pageBackground: '#dcdddc',
    white: '#ffffff',
    grey1: '#222223',
    grey2: '#7D7C83',
    grey3: '#767676',
    grey4: '#5E626D',
    grey5: '#F7F7F7',
    grey6: '#969DAD',
    grey7: '#1F1F1F',
    grey8: '#191919',
    grey9: '#DDDDDD',
    grey10: '#bdbdbd',
    grey11: '#606D82',
    grey12: '#4D4D4D',
    black: '#000000',
    blue: '#045FC9',
    blue2: '#074E92',
    error: '#B3433B',
    errorLight: '#FAE7E6',
    success: '#00A92A',
    successLight: '#D9F2E2',
};
