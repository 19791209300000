import MapTypes from '../action-types/map-sets';

const internals = {
    initial: () => ({
        mapSets: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        mapOrgSets: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
        // client data
        loadedMapSet: {
            complete: false,
            error: false,
            errorMsg: '',
            data: null,
        },
    }),
};

const MapSetReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    // NOTE: MAP_SET vs MAP_SETS servers different purposes.
    // load map sets for display in list to authenticated user
    case MapTypes.LOAD_MAP_SETS_BEGIN:
    case MapTypes.LOAD_MAP_SETS_SUCCESS:
    case MapTypes.LOAD_MAP_SETS_ERROR:
        return {
            ...state,
            mapSets: payload,
        };
    case MapTypes.LOAD_ORG_MAP_SETS_BEGIN:
    case MapTypes.LOAD_ORG_MAP_SETS_SUCCESS:
    case MapTypes.LOAD_ORG_MAP_SETS_ERROR:
        return {
            ...state,
            mapOrgSets: payload,
        };
    // singular is used for loading a map set for viewing
    case MapTypes.LOAD_MAP_SET_BEGIN:
    case MapTypes.LOAD_MAP_SET_ERROR:
    case MapTypes.LOAD_MAP_SET_SUCCESS:
        return {
            ...state,
            loadedMapSet: payload,
        };

    default:
        // do nothing
    }
    return state;
};

export default MapSetReducer;
