import OrganizationTypes from '../action-types/organizations';
import AdminTypes from '../action-types/admin';

const internals = {
    initial: () => ({
        currentOrg: {},
    }),
};

const OrganizationReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case OrganizationTypes.LOAD_ORG_SUCCESS:
        return {
            ...state,
            currentOrg: {
                ...state.currentOrg,
                complete: true,
                error: false,
                errorMsg: null,
                ...payload,
            },
        };
    case AdminTypes.DELETE_USER_SUCCESS: {
        const { id } = payload;
        const newUserSet = state.currentOrg.users?.filter((user) => user.id !== id) || [];
        return {
            ...state,
            currentOrg: {
                ...state.currentOrg,
                users: newUserSet,
            },
        };
    }

    default:
        return state;
    }
};

export default OrganizationReducer;
