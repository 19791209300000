import React, { Suspense, lazy } from 'react';
import {
    Route,
    Navigate,
    Routes,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import defaultTheme from '../styles/material-theme';

const PublicLayout = lazy(() => import('../layouts/public'));
const UserLayout = lazy(() => import('../layouts/user'));
const AdminLayout = lazy(() => import('../layouts/admin'));

const App = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const authRole = useSelector((state) => state.auth.credentials.role);

    const roleLayout = () => {
        if (isAuthenticated) {
            if (authRole === 'admin') {
                return <AdminLayout />;
            }

            return <UserLayout />;
        }
        // the user isn't authenticated
        return <Navigate replace to="/login" />;
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
                <Suspense fallback={<div />}>
                    <Routes>
                        <Route path="/admin/*" element={<>{roleLayout()}</>} />
                        <Route path="/*" element={<PublicLayout />} />
                    </Routes>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
