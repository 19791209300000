import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import colors from '../../../styles/colors';

const ErrorDescription = styled(Typography)(() => ({
    color: colors.grey4,
    fontSize: '1.6rem',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '2.9rem',
}));

export default ErrorDescription;
